<template>
    <div class="container">
        <router-view v-slot="{ Component }">
            <keep-alive :include="['actmessage', 'devicemessage', 'sysmessage']">
                <component :is="Component" />
            </keep-alive>
        </router-view>
    </div>
</template>

<script type="text/ecmascript-6">
export default {
    name: '',
    setup() {
        return {
        }
    },
    mixins: [],
    components: {},
    props: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    methods: {},
    filters: {},
    mounted() { },
    created() { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus"></style>
